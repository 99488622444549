import { ApiRestService } from '../../../Valutazioni/_service/api-rest.service';
import { Check } from '../../../Valutazioni/models/Check';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {CheckList} from "../../../Valutazioni/models/CheckList";
import {Todo} from "../../../Valutazioni/models/todo";
import {Valutazione} from "../../../Valutazioni/models/Valutazione";
import { ApiPathsService } from 'src/app/api-paths.service';
import { ClassicType } from '../../models/ClassicType';
import {IDropdownSettings} from "../../../../LibrerieCustom/MultiselectDropdown";
@Component({
  selector: 'app-check-list-valutazione',
  templateUrl: './check-list-valutazione.component.html',
  styleUrls: ['./check-list-valutazione.component.css']
})
export class CheckListValutazioneComponent implements OnInit {
  @Input() valutazione: Valutazione;
  @Input() Selected: Check[];
  @Output() SelectedChange = new EventEmitter<Check[]>();
  @Input() CheckList: CheckList;
  @Output() CheckListChange = new EventEmitter<Check[]>();
  @Input() isModifyDisabled: boolean = false;
  dropdownSettings: IDropdownSettings= { singleSelection: true, singleOutput: true, idField: 'id', textField: 'label',  allowSearchFilter: false, }
  OutcomeList: string[];
  valueList: any[];
  valueId:any;
  constructor(private ApiRestService: ApiRestService, private toastService: ToastrService,private ApiPathsService: ApiPathsService) { }
  ngOnInit() {
   
    this.ApiRestService.getchecklist({id: this.valutazione?.id}).subscribe(data=> {
      if(data){
        this.CheckList = data;
        if(this.CheckList.todo)
          this.todoDecode(this.CheckList.todo);
        this.OutcomeList = data.outcomes;
       
      }
    });
   

    this.valueList = [
      {id: -1, label:'Area non valutabile o non pertinente'},
      {id: 0, label:'Assenza di Problemi'},
      {id: 1, label:'Presenza di un Problema (intervento effettuato)'},
      {id: 2, label:'Presenza di un Problema (progetto di intervento effettuato)'},
      {id: 3, label:'Intervento in Corso'},
      {id: 4, label:'Intervento Concluso (problema in tutto o in parte risolto)'},
    ];
  }

  todoDecode(todo: Todo[]){
    todo.forEach(element => {
      if (!element.figli || element.figli.length < 1)
        element.foglie.forEach((check)=>{
          const idx: number = this.Selected.findIndex(x=>x.id == check.id);
          if( idx > -1){
            check.esito = this.Selected[idx].esito || "";
            check.valore = this.Selected[idx].valore || 0;
            check.valoreObject = this.valueList.find(x=>x.id == check.valore);
          }else
            check.modifica = true;
        });
      else
        this.todoDecode(element.figli)
    });
  }



  onChange(item: Check){
    const idx: number = this.Selected.findIndex(x => x.id == item.id)
    if(idx>-1)
      this.Selected[idx] = item;
    else
      this.Selected.push(item)
  }

  edit(item: Check) {
    if(item.modifica ){
      if (item.esito != " " && item.esito != "" && item.esito != null){
        item.modifica = !item.modifica;
        const idx: number = this.Selected.findIndex(x => x.id == item.id)
        if(idx>-1)
          this.Selected[idx] = item;
        else
          this.Selected.push(item)
      }
      else
        this.toastService.warning("inserire un Esito", "Attenzione!!");
    }else {
      item.modifica = !item.modifica;
    }
  }
  delete(item: Check) {
    const idx: number = this.Selected.findIndex(x => x.id == item.id);
    if ( idx > -1) {
      this.Selected.splice(idx, 1);
    }
  }

  value(item : Check){
    //if(item?.valoreObject){
    if(item){
      item.valore = item?.valoreObject?.id;
      item.esito = 'OK';
      if (item.esito != " " && item.esito != "" && item.esito != null){
      const idx: number = this.Selected.findIndex(x => x.id == item.id)
      if(idx>-1)
        this.Selected[idx] = item;
      else
        this.Selected.push(item)
    }
    else
      this.toastService.warning("inserire un Esito", "Attenzione!!");
  
  }
}

}
