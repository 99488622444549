<div class="form-group col-lg-12 row">
    <input type="date" name="inzio" [(ngModel)]="filtro.inizio" (ngModelChange)="Refresh()" id="data_inizio" class="form-control col-lg-3 ml-2">
    <input type="date" name="fine" [(ngModel)]="filtro.fine" (ngModelChange)="Refresh()" id="data_fine" class="form-control col-lg-3 ml-2">
</div>



<table class="table">
    <thead>
        <tr>
            <th scope="col">PTR</th>
            <th *ngFor="let date of dati.date"  class="text-info" cope="col">{{date|date:'dd/MM/yyyy'}}</th><!--da mettere ng for sulle date-->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of dati.data">
            <th scope="row">{{item.label}}</th>
            <th *ngFor="let value of item.values">{{value.valore}}</th>
        </tr>
    </tbody>
</table>