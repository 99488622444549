import { DatePipe } from '@angular/common';
import { ThisReceiver } from '@angular/compiler/src/expression_parser/ast';
import { Component, OnInit} from '@angular/core';
import { ApiPathsService } from 'src/app/api-paths.service';


@Component({
  selector: 'app-check-per-data',
  templateUrl: './check-per-data.component.html',
  styleUrls: ['./check-per-data.component.scss']
  
})
export class CheckPerDataComponent implements OnInit {
  filtro: any = {};
  dati = {
    date: ['2024-05-24', '2024-05-25'],
    data: [
    {
      label: "testo check ptr",
      values: [{value: 0, esito:'OK' },{value: 2, esito:'OK' }] // nb 1 oggetto dell'array = 1 data
    }
  ]}
  result : any;
  constructor(private ApiPaths: ApiPathsService, private datepipe: DatePipe){}
  ngOnInit(): void {
    this.filtro.inizio = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.filtro.fine = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.Refresh();
    
  }
  Refresh(){
    this.ApiPaths.ClassicPost('/task/valutazioni/day',this.filtro).subscribe(
      (data)=>{
        this.dati = data;
      }
    )
  }

  

}
